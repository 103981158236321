@import './_variables'

.form
  padding-top: 3%
  padding: 2%
  padding-bottom: 4%
  background: $silver
  margin-top: 5%
  width: 60%
  min-width: 300px

.container
  margin-top: 0
  padding-top: 1%
  display: flex
  justify-items: left
  justify-content: space-between
  width: 100%

.contact-label
  background: $gray
  border-color: black
  font-size: 1.15em
  font-weight: 600
  padding: 1em 0.5em 0.5em
  width: 22%

textarea
  height: 250px
  resize: none
  border-color: black

.contact-box
  background: $gray
  border-width: thin
  padding: 0.5em 1em 0.5em
  font-size: 1.15em
  width: 70%
  min-width: 200px

.btn-danger
  background: $gray
  color: black
  margin-top: 5%
  width: 100%
  height: 40px
  font-size: large
  font-weight: 600
  margin-top: 2.5em

  &:hover
    background: $silver
    font-weight: bold
    color: $navy
    transform: translateY(3px)
    border-radius: 3px