@import './_variables'
@import './_mixins'

*
  box-sizing: border-box
  outline: none

html,
body
  height: 100%
  overflow-x: hidden

body 
  position: relative
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: $main-text-size

code 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

* 
  box-sizing: border-box

#page-wrap
  padding-bottom: 10px
  padding-top: 10px

.page
  position: relative
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.page-header
  font-size: 4em

.text-content
  padding: 20%
  padding-top: 2%
  padding-bottom: 0
  font-size: larger
  text-align: justify

.services-header
  padding-top: 4%
  text-align: left
  font-size: x-large

.services-footer
  padding-bottom: 10%

.bookings
  margin-top: 3%
  border: 0
  height: 1000px
  width: 80%